import React from "react";

const Pause = ({ color }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.9993 27.3346C6.63555 27.3346 0.666016 21.3651 0.666016 14.0013C0.666016 6.63751 6.63555 0.667969 13.9993 0.667969C21.3631 0.667969 27.3327 6.63751 27.3327 14.0013C27.3246 21.3617 21.3598 27.3266 13.9993 27.3346ZM15.3327 8.66797V19.3346H17.9993V8.66797H15.3327ZM9.99935 8.66797V19.3346H12.666V8.66797H9.99935Z"  fill={color}/>
    </svg>
  );
};
export default Pause;
